
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import CFormSelect from "@/components/form/Select.vue";
import PCalendar from "primevue/calendar";

import { useFilter, formatDateMMAAAA } from "@/libs/utils";

const VPaymentFilter = defineComponent({
  name: "VPaymentFilter",
  components: { CFormSelect, PCalendar },
  setup() {
    useHead({ title: "Filtrar faturamento | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasAnyFilters } = useFilter();

    const establishments = computed(() => store.state.establishment.all);
    const filters = reactive({
      cd_estabelecimento: route.query.cd_estabelecimento || undefined,
      dt_competencia: route.query.dt_competencia || undefined,
    });
    const loading = reactive({ submit: false });

    async function applyFilters() {
      const dt_competencia = filters.dt_competencia ? formatDateMMAAAA(String(filters.dt_competencia)) : undefined;
      router.replace({ query: { ...route.query, ...filters, dt_competencia } });

      loading.submit = true;
      await store.dispatch("searchPayments", { search: { ...filters, dt_competencia }, isPrimary: true });
      loading.submit = false;
    }

    function resetFilters() {
      filters.cd_estabelecimento = undefined;
      filters.dt_competencia = undefined;

      applyFilters();
    }

    function handleClose() {
      router.replace({ name: "payment-list", query: route.query });
    }

    if (!establishments.value.length) store.dispatch("getAllEstablishment");
    if (hasAnyFilters.value) applyFilters();

    return { ...{ filters, loading, establishments }, ...{ applyFilters, resetFilters, handleClose } };
  },
});

export default VPaymentFilter;
